<ng-container *ngIf="isAdmin$ | async">
    <ng-container *ngIf="downloadService.activeDownloads$ | async as activeDownloads">
        <ng-container *ngIf="errors$ | async as errors">
            <button type="button" class="btn btn-icon" [ngClass]="{'colored': activeEvents > 0 || activeDownloads.length > 0, 'colored-error': errors.length > 0}" 
                [ngbPopover]="popContent" title="Activity" placement="bottom" [popoverClass]="'nav-events'" [autoClose]="'outside'">
                <i aria-hidden="true" class="fa fa-wave-square nav"></i>
            </button>
        </ng-container>
    </ng-container>
    

    <ng-template #popContent>
        <ul class="list-group list-group-flush dark-menu">

            <ng-container *ngIf="debugMode">
                <li class="list-group-item dark-menu-item">
                    <div class="h6 mb-1">Title goes here</div>
                    <div class="accent-text mb-1">Subtitle goes here</div> 
                    <div class="progress-container row g-0 align-items-center">
                        <div class="progress" style="height: 5px;">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" [attr.aria-valuenow]="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item dark-menu-item">
                    <div class="h6 mb-1">Title goes here</div>
                    <div class="accent-text mb-1">Subtitle goes here</div> 
                </li>
                <li class="list-group-item dark-menu-item">
                    <div>
                        <div class="h6 mb-1">Scanning Books</div>
                        <div class="accent-text mb-1">E:\\Books\\Demon King Daimaou\\Demon King Daimaou - Volume 11.epub</div>
                        <div class="progress-container row g-0 align-items-center">
                            <div class="col-2">{{prettyPrintProgress(0.1)}}%</div>
                            <div class="col-10 progress" style="height: 5px;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': 0.1 * 100 + '%'}" [attr.aria-valuenow]="0.1 * 100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                  </div>   
                </li>
                <li class="list-group-item dark-menu-item error">
                    <div>
                        <div class="h6 mb-1"><i class="fa-solid fa-triangle-exclamation me-2"></i>There was some library scan error</div>
                        <div class="accent-text mb-1">Click for more information</div>
                    </div>
                    <button type="button" class="btn-close float-end" aria-label="close" ></button>
                </li>
                <li class="list-group-item dark-menu-item">
                    <div class="d-inline-flex">
                        <span class="download">
                            <app-circular-loader [currentValue]="25" [maxValue]="100" fontSize="16px" [showIcon]="true" width="25px" height="unset" [center]="false"></app-circular-loader>
                            <span class="visually-hidden" role="status">
                              10% downloaded
                            </span>
                          </span>
                        <span class="h6 mb-1">Downloading {{'series' | sentenceCase}}</span>
                    </div>
                    <div class="accent-text">PDFs</div>
                </li>
            </ng-container>
            <!-- Progress Events-->
            <ng-container *ngIf="progressEvents$ | async as progressUpdates">
                <ng-container *ngFor="let message of progressUpdates">
                    <li class="list-group-item dark-menu-item" *ngIf="message.progress === 'indeterminate' || message.progress === 'none'; else progressEvent">
                        <div class="h6 mb-1">{{message.title}}</div>
                        <div class="accent-text mb-1" *ngIf="message.subTitle !== ''" [title]="message.subTitle">{{message.subTitle}}</div>
                        <div class="progress-container row g-0 align-items-center">
                            <div class="progress" style="height: 5px;" *ngIf="message.progress === 'indeterminate'">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%" [attr.aria-valuenow]="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div> 
                    </li>
                    <ng-template #progressEvent>
                        <li class="list-group-item dark-menu-item">
                            <div class="h6 mb-1">{{message.title}}</div>
                            <div class="accent-text mb-1" *ngIf="message.subTitle !== ''">{{message.subTitle}}</div>
                            <div class="progress-container row g-0 align-items-center">
                                <div class="col-2">{{prettyPrintProgress(message.body.progress) + '%'}}</div>
                                <div class="col-10 progress" style="height: 5px;">
                                    <div class="progress-bar" role="progressbar" [ngStyle]="{'width': message.body.progress * 100 + '%'}" [attr.aria-valuenow]="message.body.progress * 100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </li>
                    </ng-template>
                </ng-container>
            </ng-container>

            <!-- Single updates (Informational/Update available)-->
            <ng-container *ngIf="singleUpdates$ | async as singleUpdates">
                <ng-container *ngFor="let singleUpdate of singleUpdates">
                    <li class="list-group-item dark-menu-item update-available" *ngIf="singleUpdate.name === EVENTS.UpdateAvailable" (click)="handleUpdateAvailableClick(singleUpdate)">
                        <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>&nbsp;Update available
                    </li>
                    <li class="list-group-item dark-menu-item update-available" *ngIf="singleUpdate.name !== EVENTS.UpdateAvailable">
                        <div>{{singleUpdate.title}}</div>
                        <div class="accent-text" *ngIf="singleUpdate.subTitle !== ''">{{singleUpdate.subTitle}}</div>
                    </li>
                </ng-container>
            </ng-container>

            <!-- Active Downloads by the user-->
            <ng-container *ngIf="downloadService.activeDownloads$ | async as activeDownloads">
                <ng-container *ngFor="let download of activeDownloads">
                    <li class="list-group-item dark-menu-item">
                        <div class="h6 mb-1">Downloading {{download.entityType | sentenceCase}}</div>
                        <div class="accent-text mb-1" *ngIf="download.subTitle !== ''">{{download.subTitle}}</div>
                        <div class="progress-container row g-0 align-items-center">
                            <div class="col-2">{{download.progress}}%</div>
                            <div class="col-10 progress" style="height: 5px;">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': download.progress + '%'}" [attr.aria-valuenow]="download.progress" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
            

            <!-- Errors -->
            <ng-container *ngIf="errors$ | async as errors">
                <ng-container *ngFor="let error of errors">
                    <li class="list-group-item dark-menu-item error" role="alert" (click)="seeMoreError(error)">
                        <div>
                            <div class="h6 mb-1"><i class="fa-solid fa-triangle-exclamation me-2"></i>{{error.title}}</div>
                            <div class="accent-text mb-1">Click for more information</div>
                        </div>
                        <button type="button" class="btn-close float-end" aria-label="close" (click)="removeError(error, $event)"></button>
                    </li>
                </ng-container>
            </ng-container>

            <!-- Online Users -->
            <ng-container *ngIf="messageHub.onlineUsers$ | async as onlineUsers">
                <li class="list-group-item dark-menu-item" *ngIf="onlineUsers.length > 1">
                    <div>{{onlineUsers.length}} Users online</div>
                </li>
                <li class="list-group-item dark-menu-item" *ngIf="activeEvents < 1 && onlineUsers.length <= 1">Not much going on here</li>
                <li class="list-group-item dark-menu-item" *ngIf="debugMode">Active Events: {{activeEvents}}</li>
            </ng-container>
        </ul>
    </ng-template>
</ng-container>